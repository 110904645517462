/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios from 'axios';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useRefreshPageDeal } from '@hooks/usePageDeals';
import { dealsAction } from '../actions';
import queryClient from '../queryClient';

export function useUpdateDeal() {
  interface RequestData {
    update: any;
    ASIN: string;
    slug?: string;
  }

  const dispatch = useDispatch();
  const { mutate: refreshPageDeal } = useRefreshPageDeal();

  const mutationFn = async ({ update, ASIN }: RequestData): Promise<null> => {
    return axios.post(`/api/dashboard/update-deal`, {
      update,
      ASIN
    });
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.error('error updating deal');
    },
    onSuccess: (_data, req) => {
      queryClient.refetchQueries(`deal-summary-${req.ASIN}`);
      queryClient.refetchQueries(`deal-summary-admin-${req.ASIN}`);
      queryClient.refetchQueries('latest-deals-db');
      queryClient.refetchQueries('unmapped-deals-for-ui');

      // need to also refresh the deal list is possibly on a deal list
      // if current url contains /p/
      if (window?.location?.pathname?.includes('/p/')) {
        // everything after that is the slug name
        const slug = window?.location?.pathname?.split('/p/')[1];
        if (slug) {
          refreshPageDeal(slug);
        }
      } else if (
        window?.location?.pathname?.includes('/blog/') &&
        req?.slug === window?.location?.pathname?.split('/blog/')?.[1]
      ) {
        // get end slug
        const endSlug = window?.location?.pathname?.split('/blog/')[1];
        dispatch(dealsAction.getBlogSEO(endSlug));
      } else if (window?.location?.pathname?.includes(`/deal/${req.ASIN}`)) {
        dispatch(dealsAction.getDealSummary(req.ASIN));
      } else if (
        window?.location?.pathname?.includes(`best-amazon-subscribe-save-deals`)
      ) {
        queryClient.refetchQueries(
          `page-deal-best-subscribe-save-deals-amazon`
        );
      }
    }
  });
}

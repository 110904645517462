import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { DealPostType } from '../types';
import queryClient from '../queryClient';

const queryDealSummary = async (
  ASIN: string,
  refreshCache = false
): Promise<DealPostType> => {
  let requestStr = `/api/get-deal-summary?ASIN=${ASIN}`;
  if (refreshCache) {
    requestStr += '&refreshCache=true';
  }

  const result = await axios.get(requestStr);
  return result.data;
};

export function useGetDealSummary(ASIN: string): UseQueryResult<DealPostType> {
  return useQuery<DealPostType, Error>(
    `deal-summary-${ASIN}`,
    () => queryDealSummary(ASIN),
    {
      onError: () => {
        console.error('Something went wrong while fetching deal summary');
      }
    }
  );
}

export function useGetDealSummaryNonEnabled(
  ASIN: string,
  refreshCache = false
): UseQueryResult<DealPostType> {
  return useQuery<DealPostType, Error>(
    `deal-summary-${ASIN}`,
    () => queryDealSummary(ASIN, refreshCache),
    {
      onSuccess: () => {
        if (refreshCache) {
          queryClient.refetchQueries('latest-deals-db');
          queryClient.refetchQueries(`deal-summary-admin-${ASIN}`);
        }
      },
      enabled: false,
      onError: () => {
        console.error('Something went wrong while fetching deal summary');
      }
    }
  );
}

export function useGetDealSummaryAdmin(
  ASIN: string
): UseQueryResult<DealPostType> {
  const queryDealSummaryAdmin = async (ASIN: string): Promise<DealPostType> => {
    const result = await axios.get(
      `/api/dashboard/get-deal-summary-admin?ASIN=${ASIN}`
    );
    return result.data;
  };

  return useQuery<DealPostType, Error>(
    `deal-summary-admin-${ASIN}`,
    () => queryDealSummaryAdmin(ASIN),
    {
      enabled: false,
      onError: () => {
        console.error('Something went wrong while fetching deal summary');
      }
    }
  );
}
